"use client";
import * as m from "@/../paraglide/messages";

import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";

export default function GlobalError({ error }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <Error />
        <div>
          <h1>Sorry, an unexpected error has occurred.</h1>
          <p>Please try again later.</p>
        </div>
      </body>
    </html>
  );
}
